$roboto-font-family: 'Roboto',
  sans-serif;
@import '@angular/material/theming';
@include mat-core();
@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/nova-light/theme.css';
@import '~primeng/resources/primeng.min.css';

$mat-cyan-palette: (
  50: #e2f8ff,
  100: #C0F5E9,
  200: #85e1fb,
  300: #4AD7F2,
  400: #2eccf2,
  500: #1ac3ed,
  600: #12b2d8,
  700: #009fbd,
  800: #008aa4,
  900: #097589,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$mat-red-palette : (50: #fce9e6,
  100: #ffcbb9,
  200: #ffaa8c,
  300: #ff885d,
  400: #ff6e38,
  500: #fd5510,
  600: #f24f0b,
  700: #e54805,
  800: #d74101,
  900: #be3400,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ));

$enact-angular-primary: mat-palette($mat-cyan-palette, 700);
$enact-angular-primary-sec: mat-palette($mat-cyan-palette, 900);
$enact-angular-primary-tert: mat-palette($mat-cyan-palette, 400);
$enact-angular-primary-quad: mat-palette($mat-cyan-palette, 100);
$enact-angular-accent: mat-palette($mat-cyan-palette, 700);

// The warn palette is optional (defaults to red).
$enact-angular-warn: mat-palette($mat-red-palette, 300);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$enact-angular-theme: mat-light-theme((color: (primary: $enact-angular-primary,
        accent: $enact-angular-accent,
        warn: $enact-angular-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($enact-angular-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'inter-semibold';
  src: url('assets/fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'inter-regular';
  src: url('assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'sans-semibold';
  src: url('assets/fonts/Open_Sans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'sans-regular';
  src: url('assets/fonts/Open_Sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'roboto-medium';
  src: url('assets/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'roboto-bold';
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
}


body {
  margin: 0;
  font-family: "sans-regular" !important;
  background: #f3f3f3;
}

.aligncenter {
  text-align: center;
}

.width100 {
  width: 100%;
}

.width50 {
  width: 50%;
}

.width40 {
  width: 40%;
}

.float-left {
  float: left;
}

a {
  color: mat-color($enact-angular-primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: mat-color($enact-angular-primary-sec);
}

// button { background-color: mat-color($enact-angular-primary); color: #fff; border: none; padding: 7px 10px; border-radius: 3px; cursor: pointer; text-transform: uppercase;}
// button:hover { background-color: mat-color($enact-angular-primary-sec) !important; }
.thin-font {
  font-weight: 100 !important;
}

.margintop10 {
  margin-top: 10px !important;
}

.margintop25 {
  margin-top: 25px !important;
}

.marginbotton0 {
  margin-bottom: 0px !important;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -1px rgb(0 0 0 / 20%), 1px 1px 15px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-card,
.mat-table {
  font-family: "sans-regular" !important;
  font: 400 14px/20px "sans-regular";
}

.mat-menu-content:not(:empty) {
  padding-left: 15px;
  padding-right: 15px;
}

.mat-tab-label-active {
  color: mat-color($enact-angular-primary-sec);
  opacity: 1 !important;
}

#content .mat-tab-list .mat-tab-label-active::before {
  content: '';
  position: absolute;
  background-color: #009FBD;
  width: 8px;
  height: 54px;
  bottom: 0;
  left: 0px
}

.mat-checkbox-frame {
  border-radius: 3px !important;
}

.mat-tab-label {
  opacity: 1 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent;
}

.mat-cell,
.mat-footer-cell {
  font-size: 14px;
  color: #000 !important;
}

.mat-header-cell {
  text-transform: uppercase !important;
  color: #000;
}

.mat-dialog-title {
  padding: 24px 24px 15px;
  border-bottom: 1px solid #ddd;
}

.mat-dialog-actions {
  margin: 0 !important;
  padding: 8px 10px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

.additional-info .mat-tab-body-wrapper {
  padding-top: 24px !important;
}

.basicInfoForm .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 1);
  cursor: not-allowed;
}

.mat-expansion-panel-header.mat-expanded {
  // border-bottom: 1px solid #ddd;            //   For NEWUI-1383 as per new Billing UI
}

.mat-expansion-panel {
  margin-bottom: 10px !important;
}

.db-tree-view {
  width: 100%;
}

.selected-meter .mat-select-value {
  color: #009fbd;
  width: 85% !important;
}

.selected-house .mat-select-value {

  width: 85% !important;
}

.project-workflow .mat-tab-labels,
.project-workflow .mat-tab-label {
  display: block !important;
}

.search-bar .mat-form-field-flex {
  background: #fff;
}

.search-bar .mat-form-field-infix {
  border-top: 0 !important;
}

th.mat-header-cell {
  text-transform: uppercase;
}

.project-details .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.project-details .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent;
  bottom: 0;
}

.project-details-main .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.project-details-main .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent;
  bottom: 0;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  pointer-events: none;
}

.project-details .mat-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
}

.project-details-main .mat-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  margin-top: 0px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
}

.mat-icon {
  cursor: pointer;
  overflow: visible !important;
}

.hand-cursor {
  cursor: pointer;
}

.align-right {
  text-align: right;
}

.marginleft10 {
  margin-left: 10px !important;
}

.marginleft20 {
  margin-left: 20px;
}

.margintop20 {
  margin-top: 20px;
}

.margintop10 {
  margin-top: 10px;
}

.margintop40 {
  margin-top: 40px;
}

.margintop30 {
  margin-top: 30px;
}

.marginbottom10 {
  margin-bottom: 10px;
}

.marginbottom20 {
  margin-bottom: 20px;
}

.marginright30 {
  margin-right: 30px;
}

.marginright20 {
  margin-right: 20px;
}

.marginright10 {
  margin-right: 10px;
}

.display-inline {
  display: inline;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block;
}

.nopadding {
  padding: 0 !important;
}

.padding010 {
  padding: 10px 0px 0px 10px;
}

.padding20 {
  padding: 10px 20px 12px 20px;
}

.paddleftright20 {
  padding: 0 20px;
}

.padd20 {
  padding: 20px;
}

.paddingbottom10 {
  padding-bottom: 10px;
}

.paddingleft20 {
  padding-left: 20px;
}

.padding0 {
  padding: 0;
}

.position-relative {
  position: relative;
}

.borderbottom {
  border-bottom: 1px solid #ddd;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.border-icon {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.top-outer-wrapper {
  padding: 20px;
  overflow: hidden;
  min-height: 83.7vh;
}

.outer-wrapper {
  padding: 0px !important;
  overflow: hidden;
  min-height: 79.2vh;
}

button {
  text-transform: uppercase;
}

.font20 {
  font-size: 20px;
}

.font18 {
  font-size: 18px;
}

.font16 {
  font-size: 15px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font-roboto-bold {
  font-family: "roboto-bold";
}

.uppercase {
  text-transform: uppercase;
}

.hidden {
  display: none;
}

.primary {
  color: mat-color($enact-angular-primary);
}

.secondary {
  color: mat-color($enact-angular-primary-sec);
}

.tertiary {
  color: mat-color($enact-angular-primary-tert);
}

.icon-position {
  position: relative;
  top: 7px;
}

.caret-right {
  background: url('./assets/images/common/caret_right.svg') no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.caret-down {
  background: url('./assets/images/common/caret_down.svg') no-repeat;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.edit-icon {
  background: url('./assets/images/common/edit.svg') no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.delete-icon {
  background: url('./assets/images/common/delete.svg') no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.save-icon {
  background: url('./assets/images/common/save.svg') no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.close-icon {
  background: url('./assets/images/common/close.svg') no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
}

.form-control {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  font-family: "sans-regular";
  font-size: 14px;
}

.light {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.shadow-separator-left {
  box-shadow: 3px 0px 17px -7px rgba(0, 0, 0, 0.75);
}

.shadow-separator-right {
  box-shadow: 3px 3px 6px -3px rgba(0, 0, 0, 0.75);
}

.border-right {
  border-right: 1px solid #ddd;
}

.tree-grid th {
  font-size: 12px !important;
  font-family: "sans-regular" !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  padding: 0;
  color: #808080 !important;
}

.quote-section .db-tree-view {
  border: 0 !important;
  width: 100%;
  color: #000 !important;
}

.quote-section .db-tree-view tr th:last-child {
  width: 280px;
}

.quote-section .db-tree-view tr th:first-child {
  width: 90px;
}

.quote-section .db-tree-view div.icon-container {
  display: inline-block;
  width: 34%;
}

.quote-section .db-tree-view td.subgrid-column {
  padding: .5rem !important;
}

.quote-section .db-tree-view tr th,
.quote-section .db-tree-view tr td {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  text-align: center;
}

.quote-section .subgrid-table,
.quote-section .subgrid-table tr th,
.quote-section .subgrid-table tr td {
  border: 0 !important;
  text-align: center;
}

.tblQuotes .mat-column-action .material-icons {
  font-size: 18px;
}

.tblQuotes .mat-column-icon .material-icons {
  font-size: 30px;
  position: relative;
  bottom: 4px;
}

.main-panel {
  background: #fff;
  width: 310px;
  max-height: 585px;
  overflow-x: hidden;
}

.mat-tab-header {
  background: #fff;
}

.main-panel .mat-form-field-infix {
  width: auto;
}

.main-panel .mat-tab-label {
  min-width: 120px;
}

.block {
  display: block;
}

.none {
  display: none;
}

.configTabs .mat-tab-header {
  width: fit-content;
  height: 40px;
  min-width: 700px;
}

.configTabs.wid300 .mat-tab-body-wrapper {
  width: 310px;
  overflow: initial !important;
}

.configTabs1.wid700 .mat-tab-body-wrapper {
  width: 700px;
}

.configTabs .mat-tab-label .mat-tab-label-content {
  position: relative;
  bottom: 3px;
}

.configTabs .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 3px 10px;
  font-weight: 500;
  color: #555;
}

.configTabs .mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 1px !important;
}

.configTabs .mat-form-field-wrapper {
  padding-bottom: 7px;
}

.configTabs .mat-tab-label {
  padding: 0 10px;

  label {
    cursor: pointer;
  }
}

.main-panel .mat-tab-body-wrapper {
  width: 100%;
}

#myCustomZoomControl {
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 81px;
}

#saperatordiv {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 1px;
  margin: 0px 5px;
  background-color: rgb(230, 230, 230);
  top: 0px;
}

#vertical_saperatordiv {
  width: 1px;
  height: 30px;
  margin: 0px 5px;
  background-color: #e6e6e6;
  display: inline-block;
}

#vertical_saperatorKeepout {
  position: relative;
  overflow: hidden;
  width: 1px;
  height: 2.5em;
  margin: 0px 5px;
  background-color: rgb(230, 230, 230);
  top: .5em;
}

#zoomInButton {
  background: url('/assets/images/common/zoomin.svg') no-repeat;
  background-position: center center;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 40px;
  height: 40px;
  top: 0px;
  left: 0px;
}

#zoomOutButton {
  background: url('/assets/images/common/zoomout.svg') no-repeat;
  background-position: center center;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 40px;
  height: 40px;
  top: 0px;
  left: 0px;
}

#undo_redo_div {
  position: absolute;
  top: 5px;
  right: 106px;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #ffff;
  width: 100px;
  height: 40px;
  float: right;
  margin: 0 15px 0 0;
}

#live_div {
  position: absolute;
  top: 5px;
  right: 50px;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
  width: 60px;
  height: 40px;
  float: right;
}

.liveButtonSelect {
  background-size: cover;
  background-position: center center;
  background: #097589;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 100%;
  height: 40px;
  top: 0px;
  left: 0px;
  color: rgba(255, 255, 255) !important;
  border-radius: 3px;
  font-size: 16px !important;
  font-weight: bolder !important;
  font-family: "Roboto", sans-serif;
}

.liveButtonNotSelect {
  background-size: cover;
  background-position: center center;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 100%;
  height: 40px;
  top: 0px;
  left: 0px;
  color: rgba(74, 74, 74) !important;
  background: #ffffff;
  border-radius: 3px;
  font-size: 16px !important;
  font-weight: bolder !important;
  font-family: "Roboto", sans-serif;
}

#undoButton {
  background: url(/assets/images/common/undo.svg) no-repeat;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 0;
}

#redoButton {
  background: url(/assets/images/common/redo.svg) no-repeat;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border: 0;
}

.mapsWrapper #myCustomZoomControl {
  margin-top: 50px;
}

.mat-progress-spinner svg {
  z-index: 99999;
}

.panelArrAction .material-icons {
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 24px;
  right: -20px;
  top: 60%;
}

.invertersList {
  list-style: none;
}

.invertersList li {
  border: 2px solid #ffffff;
  overflow: hidden;
}

.invertersList .material-icons {
  cursor: pointer;
  margin-right: 10px;
  line-height: 30px;
  width: 30px;
}

.panelsArrList {
  list-style: none;
  padding: 0;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.custom-upload-img {
  width: 100%;
  height: 100%;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.sys-results {
  width: 100%;
  position: sticky;
  line-height: 45px;
  left: 0;
  top: 0;
  max-width: 700px;
}

.sys-results .main-panel {
  width: 100%;
}

.column-prefer.mat-menu-panel {
  max-height: 500px;
}

.date-input {
  width: 0 !important;
  height: 0;
}

.width80 {
  width: 80%;
}

#financetable>thead>tr {
  display: none !important;
}

#loader-div {
  top: 58px !important;
}

// Bootstrap NgbModal CSS as default css has not been added
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.d-block {
  display: block !important;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
  transition: transform .3s ease-out;
}

.custom-class .modal-dialog {
  width: 99% !important;
  height: 96% !important;
  max-width: 100% !important;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.custom-class {
  width: 95%;
  max-width: 100% !important;
  height: 96%;
  left: 2.3%;
  top: 2%;
  overflow: hidden !important;
}

.confirm-class {
  width: 40%;
  left: 30%;
  top: 30px;
}

.confirm-class .modal-content {
  width: auto;
  padding: 20px;
}

app-loader {
  .wrapper {
    z-index: 10 !important;
  }
}

.mat-menu-panel.customer-info {
  max-width: 460px;
  width: 460px;
  font-size: 13px;
}

.chartjs-render-monitor {
  height: 70vh !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.custom-menu {
  margin: 0;
  padding: 0;
}

.custom-menu li {
  list-style-type: none;
  padding: 8px 12px;
  cursor: pointer;
}

.chart-container-area .chart-contents .grid-line .tick line {
  fill: none !important;
  shape-rendering: crispedges !important;
  stroke: #000000 !important;
}

.chart-container-line .chart-contents .grid-line .tick line {
  fill: none !important;
  shape-rendering: crispedges !important;
  stroke: #e4e4e4 !important;
}

.chart-container-mixed .chart-contents .grid-line .tick line {
  fill: none !important;
  shape-rendering: crispedges !important;
  stroke: #e4e4e4 !important;
}

.chart-container-stacked .chart-contents .grid-line .tick line {
  fill: none !important;
  shape-rendering: crispedges !important;
  stroke: #e4e4e4 !important;
}

#my_dataviz .grid-line .tick line {
  fill: none !important;
  shape-rendering: crispedges !important;
  stroke: #e4e4e4 !important;
}

.proposal-setup-modal {
  position: absolute !important;
  top: 30px;
  .mat-dialog-container {
    padding: 0;
    border-radius: 6px;
  }
}

.sales_price_table {
  box-shadow: none !important;
}

.create-proj {

  .mat-dialog-content {
    margin: 0 !important;
    padding-top: 0px !important;
    max-height: 85vh !important;
  }

  .mat-dialog-actions {
    margin: 0 !important;
    padding: 6px 10px !important;
  }


}

.create-proj {

  .mat-dialog-title {
    padding: 9px 24px 15px;
    border-bottom: 1px solid #ddd;

  }

  .mat-form-field-wrapper {
    padding-bottom: 0.7em !important;

  }

  .mat-dialog-actions {
    min-height: 35px !important;
  }
}

.sys-results .main-panel-config {
  width: 100%;
}

.main-panel-config {
  background: #fff;
  width: 310px;
  max-height: 585px;
  overflow-x: hidden;

  .mat-tab-label {
    min-width: 120px;
  }

  .mat-tab-body-wrapper {
    width: 100%;
  }
}

.formPlaceHolderLabel-bs {
  color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
  font-weight: 500;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.popoverStyle {
  left: 20% !important;
}

.overview-panel .mat-input-element {
  width: auto;
  border-bottom: 1px solid #ddd;
}

.select-profile .mat-select-value {
  color: #009fbd;
}

.finance-option .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #fff;
}

.finance-option .mat-checkbox-checkmark-path {
  stroke: #5bb15b !important;
}

.finance-option .mat-expansion-panel-header {
  height: 74px;
}

.finance-option .mat-expansion-panel-body {
  max-height: 280px;
  overflow: auto;
}

.project-details-main>.mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label[aria-posinset='1'] {

  padding-left: 30px !important;
  width: 50px !important;
  min-width: 50px !important;
}

.proposal-modal .mat-dialog-content {
  max-height: 74vh !important;
}

.project-details-main>.mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label[aria-posinset='2'] {

  padding: 0px !important;
  margin-left: 0px !important;
}

.gm-style div {
  -webkit-transform: scale(1.002);
}

.additionalForm-info .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1em;
}

.mat-button-toggle-checked .mat-button-toggle-label-content {
  background: #009fbd;
  color: black;
}

app-project-document .mat-paginator-container {
  align-items: inherit !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  margin-left: 2px;
}

.listing .mat-form-field-infix {
  display: flex !important;
}

.layout-control {
  background-color: #ffffff;

  font-size: 14px;
  font-weight: 500;
}

nav {
  padding: 0px 15px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgba(74, 74, 74, 1) !important;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 3px solid #009fbd !important;
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #ffffff;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 0 !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: rgba(74, 74, 74, 0.6) !important;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  outline: none !important;
}

.layout-control:active {
  color: #009fbd;
  font-family: $roboto-font-family;
  background: rgba(255, 255, 255, 1);
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem
}

.tab-content {
  position: relative;
  top: 39px;
  background-color: #ffffff;
}

.tab-content nav {
  padding: 0px 15px;
}

.tab-content .form-group {
  padding-left: 20px;
}

.graphModalWrapper .tab-content {
  top: 0px;
}

.tab-pane.active.show#panel-conf,
.tab-pane.active.show#inverter,
.tab-pane.active.show#storageConfig,
.tab-pane.active.show#add-choices,
.tab-pane.active.show#sys-results {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 650px;
}

.fade:not(.show) {
  opacity: 0;
  display: none !important;
}

button.marginleft10.uppercase.alert {
  color: #009fbd;
  padding: 5px;
  margin-bottom: 10px;
  float: right;
  margin-right: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.mat_56 .mat-form-field-infix {
  margin: 0px !important;
  padding: 0px !important;
  border: 0;
}

.only_this_mata span {
  margin: 0 !important;
  padding: 0 !important;
}

.only_this_mata {
  display: flex;
  align-items: center;
}

.only_this_mata .mat-form-field-infix.ng-tns-c113-3 {
  max-width: 130px;
}

.second_part .mat-form-field-infix.ng-tns-c113-5 {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.second_part .mat-form-field-infix.ng-tns-c113-6 {
  padding: 0 !important;
  margin: 0 !important;
}

.second_part .mat-form-field-wrapper {
  top: -0.9em;
}

.paddleftright20.paddingbottom10 label,
.paddleftright20.paddingbottom10 input {
  font-size: 14px !important;
}

.second_part .mat-form-field-flex.ng-tns-c113-5 {
  padding: 0 !important;
  margin-top: 1em;
}

.second_part .mat-form-field-flex.ng-tns-c113-6 {
  padding: 0;
  margin-top: 1em;
}

.width100.padd20.borderbottom.second_part.ng-tns-c424-2 {
  padding: 15px 25px 10px 25px;
}

.flex-bill .floatright.paddleftright20.paddingbottom10.font16.ng-tns-c424-2 {
  float: none;
  margin: 0;
  padding: 0;
}

.flex-bill .padd20.font16.ng-tns-c424-2 {
  margin: 0;
  padding: 0;
}

.flex-bill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 20px 20px;
}

.paddleftright20.paddingbottom10 {
  // padding-bottom: 20px;
}

.mat-expansion-panel-body br {
  display: none;
}

// input#mat-input-0,
// input#mat-input-1 {
//   color: #009fbd;
//   font-size: 15px;
// }

.mat-form-field-wrapper.ng-tns-c113-5 {
  max-width: 250px;
}

.mat-form-field-wrapper.ng-tns-c113-6 {
  max-width: 250px;
}

.rate>.mat-form-field-wrapper.ng-tns-c113-5 {
  max-width: 600px;
}

.rate>.mat-form-field-wrapper.ng-tns-c113-6 {
  max-width: 600px;
}

.utility>.mat-form-field-wrapper.ng-tns-c113-5 {
  max-width: 600px;
}

.utility>.mat-form-field-wrapper.ng-tns-c113-6 {
  max-width: 600px;
}

.project-info {
  align-items: center;
  width: 100% !important;
  display: flex;
}

label.lbl-field {
  font-size: 16px;
  color: #000;
}

span.ng-tns-c424-2 {
  font-size: 16px;
}

.mat-button-toggle-checked .mat-button-toggle-label-content {
  background: #009fbd;
  color: #fff !important;
}

.on__lb {
  text-align: center !important;
  float: none !important;
  margin: 0 !important;
}

label.lbl-field.month___lb {
  text-align: center !important;
  margin: auto !important;
  display: block !important;
  float: none !important;
}

.mar___l {
  margin-left: 0 !important;
}

button.removeMeter.ng-tns-c424-2.ng-star-inserted {
  margin: 0 !important;
}

.project-details .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none !important;
}

.ghor .edit-bottom {
  border-bottom: 1px solid #ddd;
}

.ghor .font18.header-position.ng-tns-c424-2 span {
  color: #000;
}

.ghor .mat-form-field-infix {
  border-top: 0;
  padding: 10px 0 !important;
}

.loresum_t {
  display: flex;
  align-items: center;
  color: #009fbd;
}

.loresum_t .mat-select-arrow {
  color: #009fbd;
}

.custom__met {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px !important;
  padding: 16px !important;
}

.custom__met .font18.header-position.ng-tns-c424-2 {
  margin: 0 0 7px 0;
}

.width100.demo__class {
  align-items: center;
  margin: 0 0 15px 0;
}

@media(max-width:1400px) {

  .paddleftright20.paddingbottom10 label,
  .paddleftright20.paddingbottom10 input {
    font-size: 10px !important;
  }

  .margintop10 {
    margin-top: 0px !important;
  }

}

.noPadd.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 !important;
  border: none !important;
  margin: 10px 0 0 0 !important;
}

button.error-btn {
  color: #fff !important;
  background: #009fbd;
  border: 1px solid;
  padding: 5px;
  margin: 0px 5px;
  border: 1px solid currentColor;
  padding: 0 15px;
  line-height: 34px;

}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.custom-modalbox {
  .mat-dialog-title {
    padding: 10px 16px 5px;
    border-bottom: 1px solid #ddd;
    margin: 0 !important;
  }
}

.custom-modalbox {
  .mat-dialog-container {
    padding-bottom: 0;
  }
}

.kwhtogglebutton>.mat-button-toggle-button>.mat-button-toggle-label-content {
  text-transform: none !important;
}

.selected-house .mat-select-value {
  color: #009fbd !important;
}

.selected-house .mat-select-arrow {
  color: #009fbd !important;
}

// div#mat-autocomplete-1{
//   width: 351px !important;
//   top: 17.125px !important;
//   left: -8.15px !important;
//   border: 1px solid #009fbd !important;
//   border-radius: 5px !important;
// }
// div#mat-autocomplete-0{
//   width: 350px !important;
//   top: 16.875px !important;

//   border: 1px solid #009fbd !important;
//   border-radius: 5px !important;
// }
// div#cdk-overlay-0{
//   left:545px  !important;
//   width:345px !important;
// }
.mat-autocomplete-panel.mat-autocomplete-visible {
  margin-left: -0.7em !important;
  margin-top: 1em !important;
}

.margintop15 {
  margin-top: 15px !important;
}

.unitOfMeasurementdd {
  height: 50px;
  width: 30em;
}

.unitOfMeasurementdd>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix {
  height: 50px;
}

#mat-dialog .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: hidden !important;
}

.mat-dialog-content {
  display: block;
  margin: 0 -24px;
  padding: 0 24px;
  max-height: 100%;
  overflow: hidden !important;
  // -webkit-overflow-scrolling: touch;
}

.marginTop mat-form-field.mat-form-field {
  width: 100%;
}

.testClass {
  position: absolute;
  top: 32px;
  left: 4px;
  min-width: calc(100% + 22px) !important;
  border-radius: 6px !important;
  border: 1px solid #CACDD5;
  border-top-style: none;
  font-size: 18px;
  font-weight: 500;
}


.marginTop {

  & span.mat-select-placeholder {
    color: #525C76;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

  }

  & .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.8em 0 1em 0 !important;
  }
}

.toast-top-center{
  left: 30% !important;
  transform: translate(50%) !important;
}

// .isFocused .mat-form-field-flex .mat-form-field-outline {
  .isFocused .mat-form-field-flex   {
  animation: horizontal-shaking 0.35s infinite;

}
@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
 }

/** system-details-toolbar-popup **/
.system-details-toolbar-popup{
  .mat-dialog-container{
    padding: 0 !important;
  }
}
/** end-system-details-toolbar-popup **/


.tooltip-ellipses {
  position: relative;
  display: inline-block;
   .tooltiptext {
    visibility: hidden;
    position: absolute;
    background: #050e20;
    color: #fcfcfc;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    &.tooltip-bottom {
      top: 135%;
      left: 50%;
      margin-left: -60px;
      padding: 0.3rem 0.5rem;
      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #050e20 transparent;
      }
    }
    &.tooltip-top {
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      padding: 0.3rem 0.5rem;
       &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #050e20 transparent transparent transparent;
    }
  }
    
  }
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}
.mat-form-field-required-marker { 
  color: red;
  font-size:18px;
  font-family:Inter;
 }

.proposal-template-warning .mat-dialog-container {
 h1{
  padding: 20px 0px 15px 5px;
 }
}